module.exports = {
	"oauthurl": "https://accounts.google.com/o/oauth2/auth",
	"scopes": [
	"https://www.googleapis.com/auth/userinfo.email",
	"https://www.googleapis.com/auth/userinfo.profile",
	"https://www.googleapis.com/auth/plus.me",
	"https://www.googleapis.com/auth/youtube.readonly",
	],
	"join_scopes": [
		"https://www.googleapis.com/auth/userinfo.email",
		"https://www.googleapis.com/auth/userinfo.profile",
		"https://www.googleapis.com/auth/plus.me",
		"https://www.googleapis.com/auth/youtube.readonly",
	],
	"clientId": "575527060586.apps.googleusercontent.com",
	"redirectURL": "https://join.quizgroup.com/gauth_callback/",
	"type": "token",
	"domain": "https://join.quizgroup.com",
	"urls":{
		"api_account_ping": "/api/account/user_status",
		"api_account_google": "/api/account/google_login?token=",
		"api_account_pswd_gauth_login": "/api/account/pswd_gauth_login",
		"api_account_logout": "/api/account/logout",
		"api_inbox_news_list": "/api/inbox/news_list",
		"api_dashboard_get_params": "/api/dashboard/get_params",
		"api_dashboard_get_channel_list": "/api/dashboard/get_channel_list"
	}
};
