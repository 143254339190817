"use strict";
window.$ = window.jQuery = require("jquery");
window._ = require("lodash");
window.Backbone   = require("backbone");
window.Marionette = require("backbone.marionette");
window.Radio = require("backbone.radio");
window.Highcharts = require("highcharts");
require("backbone.syphon");
require("backbone.validation");
require("backbone.paginator");
window.i18n = require("i18next");
require("backbone.forms");
require("jquery-ui-autocomplete");
window.GlobalConfig = require("config");
require("bootstrap");
require("jquery.dateSelectBoxes");
require("slick-carousel");
require("data-tables");
require("soundmanager");
require("chosen");
require("circle-progress");
require("nicescroll");
require("blueimp-fileupload");
require("blueimp-iframe-transport");
require("blueimp-widget");
require("pdfjs-compatibility");
require("pdfjs");
require("pdfjs-worker");
require("highcharts-3d");
require("dataTables-sorting");



